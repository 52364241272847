import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';

import Layout from '../components/layout';


function InsuranceDictionary() {

  const data = useStaticQuery( graphql`
  query {
    allStrapiPageData(filter: {page_name: {eq: "glossary_page"}}) {
      edges {
        node {
          data_category
          column
          content
          style_category
          attribute_1_value
          attribute_2_value
          attribute_1_key
          attribute_2_key
        }
      }
    }
  }
  ` );

  function updateLinks() {
    const anchor = document.querySelectorAll( '.linktagtext li a' );
    anchor.forEach( ( link ) => {
      const { dataset } = link;
      link.href = `${window.location.pathname}${dataset.href}`;
    } );
  }

  useEffect( updateLinks, [] );

  return(
    <>
      <Layout header={'menu'}>
        <div className="insurance-dictionary-wrapper">
          <div className="wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h2>Lorem Ipsum</h2>
                </div>
              </div>

              <div className="row">
                <div className="col-12 text-center linktagbg">
                  <ul className="linktagtext">
                    <li><a data-href="#a">A</a></li>

                    <li><a data-href="#b">b</a></li>

                    <li><a data-href="#c">c</a></li>

                    <li><a data-href="#d">d</a></li>

                    <li><a data-href="#e">e</a></li>

                    <li><a data-href="#f">f</a></li>

                    <li><a data-href="#g">g</a></li>

                    <li><a data-href="#h">h</a></li>

                    <li><a data-href="#i">i</a></li>

                    <li><a data-href="#j">j</a></li>

                    <li><a data-href="#k">k</a></li>

                    <li><a data-href="#l">l</a></li>
                  </ul>
                </div>

                <div className="col-12">
                  <div className="row" id="a">
                    <div className="col-12">
                      <div className="scrolldiv">
                        <h2>A</h2>

                        <div className="datalist">
                          <ul className="row">
                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="row" id="b">
                    <div className="col-12">
                      <div className="scrolldiv">
                        <h2>B</h2>

                        <div className="datalist">
                          <ul className="row">
                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>
                          </ul>
                        </div>
                      </div>

                    </div>

                  </div>

                  <div className="row" id="c">
                    <div className="col-12">
                      <div className="scrolldiv">
                        <h2>c</h2>

                        <div className="datalist">
                          <ul className="row">
                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>
                          </ul>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="row" id="d">
                    <div className="col-12">
                      <div className="scrolldiv">
                        <h2>d</h2>

                        <div className="datalist">
                          <ul className="row">
                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row"  id="e">
                    <div className="col-12">
                      <div className="scrolldiv">
                        <h2>e</h2>

                        <div className="datalist">
                          <ul className="row">
                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row"  id="f">
                    <div className="col-12">
                      <div className="scrolldiv">
                        <h2>f</h2>

                        <div className="datalist">
                          <ul className="row">
                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row"  id="g">
                    <div className="col-12">
                      <div className="scrolldiv">
                        <h2>g</h2>

                        <div className="datalist">
                          <ul className="row">
                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row"  id="h">
                    <div className="col-12">
                      <div className="scrolldiv">
                        <h2>h</h2>

                        <div className="datalist">
                          <ul className="row">
                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>

                            <li className="col-md-4 col-12">
                              <a href="">Lorem Ipsum</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default InsuranceDictionary;
